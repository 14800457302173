<i18n lang="json">
{
	"ru": {
    "bonusTitle": "Кэшбэк",
    "bonuses": "{n}&nbsp;бонусов | {n}&nbsp;бонус | {n}&nbsp;бонуса | {n}&nbsp;бонусов",
    "hint": "<strong>Бонусная программа Суточно.ру</strong><br>Начисляется после оценки проживания — <br> в виде бонусов на ваш баланс.<br>Чем больше суток бронируете, тем больше бонусов получаете.<br><a href='https://promo.sutochno.ru/bonus-za-prozhivanie' target='_blank'>Подробнее</a>"
	},
	"en": {
    "bonusTitle":"Cashback —",
    "bonuses": "0|{n}&nbsp;bonus|{n}&nbsp;bonuses|{n}&nbsp;bonuses",
    "hint": "<strong>Bonus program Sutochno.ru</strong><br>Accrued after evaluating your stay - in the form of bonuses to your balance. The more days you book, the more bonuses you receive. <br><a href='https://promo.sutochno.ru/bonus-za-prozhivanie' target='_blank'>More details</a>"
	}
}
</i18n>

<template>
  <div class="bonus" v-if="bonus">
    <p class="bonus__item tmp-font--small">
      <span class="bonus__value">{{ t("bonusTitle") }}</span>
      <span class="bonus__text" v-html="t('bonuses', bonus)"></span>
      <VDropdown
        theme="tooltip"
        class="bonus__popover"
        distance="12"
        arrow-padding="8"
        placement="top"
        :triggers="['click']"
        popper-class="v-popper__popper--max-width-300"
        auto-hide
      >
        <span class="ico-question">?</span>
        <template #popper>
          <div class="tooltip-text">
            <p class="text-left" v-html="bonusDesc"></p>
            <div v-close-popper class="tooltip-close"></div>
          </div>
        </template>
      </VDropdown>
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
});

const { t } = useI18n();

const bonus = computed(() => {
  if (props.value >= 100000) {
    return 100000;
  }
  return props.value;
});

const bonusDesc = computed(() => t("hint", { n: t("bonuses", bonus.value) }));

</script>

<style lang="scss" scoped>
.bonus {
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f6f6;

    border: solid 1px #417505;
    border-radius: 3px;

    color: #417505;

    padding: 7px 10px;
  }
  &__value {
    white-space: pre;
  }
  &__text {
    padding: 0 3px 0 5px;
    white-space: pre;
  }
  .bonus__popover {
    margin-left: 2px;
  }
  .ico-question {
    cursor: pointer;
    border: 1px solid #417505;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0;
    font-size: 10px;
  }
}
</style>
