// import HTTP from "../http-common";
import axios from "axios";

/**
 * Получение настроек для вывода кнопки "Показать телефон"
 *
 * @module settings_phone
 * @return {object} Promise
 */
export default () => {
  return axios.get(`https://sutochno.ru/doc/files/settings_phone.js`);
};
