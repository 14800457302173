<template>
  <div
    v-if="screenSize == 'desktop'"
    v-click-outside="clickOutside"
    class="select"
    @click="visible = !visible"
  >
    {{ variants[value]["text"] }}

    <ul v-if="visible" class="select-list">
      <li
        v-for="(variant, index) in variants"
        :key="index"
        class="select-list-elem"
        :class="{
          'select-list-elem__active': value == index,
          'select-list-elem__disabled': variant.disabled,
        }"
        @click="value = index"
      >
        {{ variant.text }}
      </li>
    </ul>
  </div>
  <div v-else class="select">
    <select v-model="value" class="select_mobile">
      <option
        v-for="(variant, index) in variants"
        :key="index"
        class="select-list-elem"
        :selected="index == value"
        :value="index"
        :disabled="variant.disabled"
      >
        {{ variant.text }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BaseSelect",
  props: {
    variants: {
      type: Object,
    },
  },
  data() {
    return {
      value: this.$attrs.modelValue || 0,
      visible: false,
    };
  },
  computed: {
    ...mapState({
      screenSize: (state) => state.user.viewPort,
    }),
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
    visible() {
      this.$emit("visible", this.visible);
      // if(this.$attrs.visible) {
      //   this.visible = this.$attrs.visible;
      // }
    },
    "$attrs.modelValue": function (value) {
      this.value = value;
    },
    "$attrs.visible": function (visible) {
      this.visible = visible;
    },
  },
  methods: {
    clickOutside() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.select {
  // background-image: url("../../assets/arrow.svg");
  background-image: url("~/assets/img/ico-select.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  // padding-left: 24px;
  padding-right: 24px;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  max-width: 100%;
  // overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  &--small {
    font-size: 15px;
  }

  &-list {
    position: absolute;
    z-index: 70;
    display: block;
    line-height: 2;
    border: 1px solid #d8d8d8;
    background: #fff;
    color: #444444;
    margin: 8px 0 0 0;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding: 10px 0;
    max-width: 100%;

    &-elem {
      display: block;
      padding: 0 10px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover,
      &:active,
      &__active {
        background: #edeceb;
      }
      &__disabled {
        pointer-events: none;
        opacity: 0.64;
      }
    }
  }
  &--right {
    .select-list {
      right: 0;
    }
  }
}
.select_mobile {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: none;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  padding: 0 32px 0 10px;
  color: #444;
  cursor: pointer;
}
</style>
