<i18n lang="json">
{
	"ru": {
    "in": "Заезд",
		"btn": "Сохранить",
		"out": "Отъезд",
		"outLower": "отъезд",
		"reset": "Сбросить даты",
    "pressDate": "Введите даты поездки чтобы увидеть цены",
    "titleCalendar": "Выбор дат",
    "titleGuests": "Выбор гостей",
    "titleBooking": "Бронирование",
    "choiceOut": "Выберите дату отъезда",
    "nights": "менее суток | за сутки | за {n} суток | за {n} суток",
    "nightsMin": "менее суток | {n} сутки | {n} суток | {n} суток"
	},
	"en": {
	}
}
</i18n>
<template>
  <div class="booking-datepicker">
    <div class="loading" v-if="!viewCalendar" />
    <template v-else>
      <div class="booking-datepicker__header">
        <p class="tmp-font--big_bold">
          <span v-if="dateViews.in && dateViews.out">
            {{ `${format(date.in)} — ${format(date.out)}` }}
            <template v-if="nextYear">{{ `, ${nextYear}` }}</template>
          </span>
          <span v-else-if="dateViews.in">{{
            `${format(dateViews.in)} — ${t("outLower")}`
          }}</span>
          <span v-else>{{ t("in") }}</span>
        </p>
        <p class="tmp-font--medium_gray booking-datepicker__header-text">
          <span v-if="dateViews.in && dateViews.out">{{
            t("nightsMin", daysCount)
          }}</span>
          <span v-else-if="dateViews.in">{{ t("choiceOut") }}</span>
          <span v-else>{{ t("pressDate") }}</span>
        </p>
      </div>
      <div class="main-datepicker" v-if="viewCalendar">
        <div class="main-datepicker-calendar" id="calendar">
          <Calendar
            ref="CalendarRef"
            v-model="date"
            v-model:dateViews="dateViews"
            v-model:daysCount="daysCount"
            changeRange="in"
            :begin-date="new Date()"
            :start-disable="new Date()"
            :count-month="13"
            :employment="employment"
            :cntMin="cntMin"
            orientation="vertical"
            lang="ru-RU"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Calendar } from "@sutochno/datepickerext";
import { mapState } from "vuex";
import _ from "lodash";
import { useI18n } from 'vue-i18n';

export default {
  name: "CalendarMobile",
  components: {
    Calendar
  },
  props: {
    bookingView: {
      type: Boolean,
      default: false,
    },
    cntMin: {
      type: Number,
      default: null
    },
    employment: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      date: {},
      dateViews: {
        in: null,
        out: null,
      },
      daysCount: 0,
      viewCalendar: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      if(this.checkIn && this.checkOut) {
        this.date = {
          in: new Date(this.checkIn.setHours(0)),
          out: new Date(this.checkOut.setHours(0))
        }
      }
      setTimeout(() => {
        this.viewCalendar = true
      }, 350);
    })
  },
  watch: {
    isSelectedDate(value) {
      this.$emit("choice", value);
    },
    watchDateIn(val, oVal) {
      if (val !== oVal) {
        this.$emit('send')
      }
    },
    daysCount(val) {
      this.$emit('update:daysCount', val)
    },
    dateViews: {
      deep: true,
      handler(val, oVal) {
        if(
          val
          && val.in?.getTime() === this.checkInHoursChanged?.getTime()
          && val.out?.getTime() === this.checkOutHoursChanged?.getTime()
        ) {
          return false
        }

        this.$emit('update:dateViews', val)
        this.$emit('disabled', 
          val?.in && val?.out
            ? false : true
        )

        if(
          val &&
          ((val.in && val.out) ||
          (val.in && val.error?.getTime() > val.in.getTime()))
        ) {
          this.setOccupied(val.in, val.out || val.error)
        }
        const send = () => {
          setTimeout(() => {
            this.$emit('cntCheck')
            this.$emit('send')
          }, 0);
        };
        if (val?.in !== oVal?.in) {
          send();
        } else {
          setTimeout(() => {
            if (this.daysCount >= this.cntMin) {
              send();
            }
          }, 0);
        }
      },
    },
  },
  computed: {
    ...mapState("search", ["checkIn", "checkOut"]),
    watchDateIn() {
      // watch-ер не может просматривать old value у свойства объекта
      // а мне нужно обязательно проверить что старое значение не совпадает с новым
      // по этому я возвращаю в виде одной даты и уже этот computed смотрю в watch
      return this.dateViews?.in;
    },
    watchDateOut() {
      // watch-ер не может просматривать old value у свойства объекта
      // а мне нужно обязательно проверить что старое значение не совпадает с новым
      // по этому я возвращаю в виде одной даты и уже этот computed смотрю в watch
      return this.dateViews?.out;
    },
    nextYear() {
      if (this.date?.in && this.date.out) {
        const nextY = new Date(this.date.out).getFullYear();
        const nowY = new Date().getFullYear();
        return nextY > nowY ? nextY : null;
      } else {
        return null;
      }
    },
    checkInHoursChanged(){
      return this.checkIn instanceof Date ? new Date(this.checkIn.setHours(0)) : new Date();
    },
    checkOutHoursChanged(){
      return this.checkOut instanceof Date ? new Date(this.checkOut.setHours(0)) : new Date();
    },
    isSelectedDate() {
      return this.dateViews.in || this.dateViews.out;
    },
    dateCheck() {
      if (this.getObjectData.properties.check_in_out_rules) {
        return this.getObjectData.properties.check_in_out_rules.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      } else {
        return this.getObjectData.properties.additional_payment.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      }
    },
  },
  methods: {
    format(date) {
      return date
        .toLocaleString("ru-RU", {
          day: "numeric",
          month: "short",
        })
        .replace(".", "");
    },
    reset() {
      this.date.in = null;
      this.date.out = null;
      this.$refs.CalendarRef.reset();
      this.setOccupied(this.date.in, this.date.out);
      this.$emit("reset")
      this.$emit("update:modelValue", this.date);
    },
    setOccupied(dateIn, dateOut) {
      this.$store.dispatch("search/setCalendarDates", {
        checkIn: dateIn,
        checkOut: dateOut,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.booking {
  &-datepicker {
    &__reset {
      position: absolute;
      top: 0px;
      right: 10px;
      z-index: 201;
      font-size: 14px;
    }
    &__header {
      padding: 20px;
      background: #fff;

      &-text {
        margin-top: 10px;
      }
    }
  }
}
.main-datepicker {
  &-calendar {
    :deep() {
      .sc-datepickerext-calendar {
        &.sc-datepickerext-vertical {
          // height: calc(var(--vh, 1vh) * 100 - 226px);
          height: 100%;
          overflow: hidden;
        }
        .sc-datepickerext-calendar_body {
          height: calc(var(--vh, 1vh) * 100 - 271px);
          overflow: auto;
        }
      }
    }
  }
}
</style>
